import * as React from "react"
import Layout from "../../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import ImageDownload from "../../../components/ImageDownload"

const IndexPage = () => {
    const root = "/img/download/awards/standard-graphics"

    return (
        <Layout pageTitle="Virtual Awards - Standard Graphics">
            <Row>
                <Col>
                    <Link to="/awards/small-business-awards">Back</Link>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={8}>
                <p className="para">
                        Get even more eyes on your business by sharing and
                        encouraging your network to get involved in this year's
                        Women's Busines Awards!
                    </p>
                    <p className="para">
                        Elevate your status as an award nominee and an AWARD WINNER!
                    </p>
        
    
                </Col>
            </Row>
            <ImageDownload
                root={root}
                name="Virtual Awards Promo"
                filename="Virtual Awards Promo (horizontal)"
                text="Click to see a selection of promo graphics for your use. You can use and adapt the marketing copy below! Make sure you only use copy for VIRTUAL AWARDS"
                squareHref="https://www.canva.com/design/DAFeHkurf68/2znnchHqFhwyti-ROxzwpw/view?utm_content=DAFeHkurf68&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                horizontalHref="https://www.canva.com/design/DAFeHQ3ytSM/cPX1pXWXu9LnrnZrxj1q9Q/view?utm_content=DAFeHQ3ytSM&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                copyHref="https://docs.google.com/document/d/10puqRIqzuL1TfqG1yAitHJGZhCpBrmJrJVIRgRuz6AQ/edit?usp=sharing"
            />
        </Layout>
    )
}

export default IndexPage
